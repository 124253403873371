import { HttpOptionsModel } from '@amaris/lib-highway/models';
import { HttpClientService, TranslationService } from '@amaris/lib-highway/services';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  Observable, catchError, map, of,
  tap,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { SubscriptionPayload } from '../../../models/subscription-payload.model';
import { ApiResponse } from '../../../models/api-response.model';
import { SubscriptionOfferGroup } from '../../../models/subscription-offer-group.model';
import { SetupIntentPayload } from '../../../models/setup-intent-payload.model';
import { UserSubscription } from '../../../models/user-subscription.model';
import { DataStoreService } from '../../data-store/data-store.service';
import { InvoiceStatus } from '../../../models/invoice-status.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  // eslint-disable-next-line max-len

  constructor(
    private readonly httpClientService: HttpClientService,
    private readonly toastrService: ToastrService,
    private readonly dataStoreService: DataStoreService,
    private readonly translationService: TranslationService,
  ) { }

  initSubscription(payload: SubscriptionPayload): Observable<ApiResponse<string>> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Subscriptions,
      body: payload,
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      catchError((error: any) => {
        if (error?.error?.properties?.stripeErrorCode === 'customer_tax_location_invalid') {
          this.toastrService.error(this.translationService.getTranslation(`core.exception.${error.error.properties?.stripeErrorCode}`));
        } else {
          this.toastrService.error('Une erreur s\'est produite lors de la souscription à l\'abonnement. Veuillez réessayer.');
        }
        return of(null);
      }),
    );
  }

  getUserSubscription(): Observable<UserSubscription> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Subscriptions,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<UserSubscription[]>>(options).pipe(
      map((response) => {
        if (!response.data?.length) {
          return null;
        }
        return new UserSubscription(response.data[0]);
      }),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de la récupération des offres d\'abonnement. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  getSubscriptionOffers(): Observable<SubscriptionOfferGroup[]> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Subscriptions + ApiEndpoints.Plans,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<SubscriptionOfferGroup[]>>(options).pipe(
      map((response) => response.data),
      tap((offers: SubscriptionOfferGroup[]) => {
        offers.forEach((offerGroup) => {
          // prices should be ordered by amount
          offerGroup.prices.sort((a, b) => a.unitAmount - b.unitAmount);
        });
        this.dataStoreService.setSubscriptionPlans(offers);
      }),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de la récupération des offres d\'abonnement. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  checkCoupon(coupon: string): Observable<any> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Subscriptions + ApiEndpoints.PromotionCodes}/${coupon}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<any>>(options).pipe(
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de la vérification du code de promotion. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  setupIntent(payload: SetupIntentPayload): Observable<any> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Subscriptions + ApiEndpoints.SetupIntent,
      body: payload,
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<any>>(options).pipe(
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de la création du paiement. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  getLastInvoiceStatus(): Observable<InvoiceStatus> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl
      + ApiEndpoints.Subscriptions
      + ApiEndpoints.Invoices
      + ApiEndpoints.Last
      + ApiEndpoints.Status}/${this.dataStoreService.connectedUser$.value.email}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<InvoiceStatus>>(options).pipe(
      map((response) => response.data),
      catchError(() => of(null)),
    );
  }

  cancelSubscriptionRenew(payload: UserSubscription): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Subscriptions + ApiEndpoints.Cancel}/${payload.id}`,
      body: payload,
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      tap((response: ApiResponse<string>) => {
        if (response.success && response.data) {
          this.dataStoreService.setConnectedUserSubscriptionRenwable(false);
        }
      }),
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de l\'arrêt du renouvelement. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  undoCancelSubscriptionRenew(payload: UserSubscription): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Subscriptions + ApiEndpoints.Undo + ApiEndpoints.Cancel}/${payload.id}`,
      body: payload,
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      tap((response: ApiResponse<string>) => {
        if (response.success && response.data) {
          this.dataStoreService.setConnectedUserSubscriptionRenwable(true);
        }
      }),
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('Une erreur s\'est produite lors de l\'activation du renouvelement. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }
}
