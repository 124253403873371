import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentApiService } from '../apis/payment-api/payment-api.service';
import { DataStoreService } from '../data-store/data-store.service';
import { InvoiceStatus } from '../../models/invoice-status.model';
import { UserSubscription } from '../../models/user-subscription.model';

@Injectable({
  providedIn: 'root',
})
export class ApiPollingService {
  constructor(
    private readonly paymentApiService: PaymentApiService,
    private readonly dataStoreService: DataStoreService,
    private readonly toastrService: ToastrService,
  ) { }

  pollLastInvoiceStatus(): void {
    // poll paimentApiService.getLastInvoiceStatus() every second
    // if polling returns result.paymentValid, strop polling, clear datastoreservice.paymentAwaitingValidation$
    // show a toastr message saying the payment validation was successful
    let counter = 0;
    const interval = setInterval(() => {
      this.paymentApiService.getLastInvoiceStatus().subscribe((result: InvoiceStatus) => {
        counter += 1;
        if (result && result.paymentValid) {
          clearInterval(interval);
          this.dataStoreService.paymentAwaitingValidation$.next(null);
          this.toastrService.success('Payment validation was successful');
          this.paymentApiService.getUserSubscription().subscribe((userSubscription: UserSubscription) => {
            this.dataStoreService.setConnectedUserSubscription(userSubscription);
          });
        }

        if (counter > (60 / 3) * 10) {
          clearInterval(interval);
          this.toastrService.error(
            'La validation du paiement à expirée. veuillez recharger l\'application et réésayer',
            null,
            { disableTimeOut: true },
          );
        }
      });
    }, 3000);
  }
}
