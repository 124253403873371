import { EventEmitter, Injectable } from '@angular/core';
import { AuthentificationService } from '@amaris/lib-highway/services';
import { TokenModel } from '@amaris/lib-highway/models';
import {
  catchError,
  combineLatest, finalize, switchMap, take,
} from 'rxjs';
import { AuthService, User as OAuthUser } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { UsersApiService } from '../apis/users-api/users-api.service';
import { ApiResponse } from '../../models/api-response.model';
import { User } from '../../models/user.model';
import { DataStoreService } from '../data-store/data-store.service';
import { MedicalPracticesService } from '../apis/mediacal-practices/mediacal-practices.service';
import { UserSubscription } from '../../models/user-subscription.model';
import { PaymentApiService } from '../apis/payment-api/payment-api.service';
import { InvoiceStatus } from '../../models/invoice-status.model';
import { PaymentService } from '../payment/payment.service';

@Injectable({
  providedIn: 'root',
})
export class UserInitializationService {
  public userInitialized: EventEmitter<User> = new EventEmitter<User>();
  public userSubscribed: EventEmitter<User> = new EventEmitter<User>();
  public oAuthUserInitialized: EventEmitter<OAuthUser> = new EventEmitter<OAuthUser>();

  public readonly maxTry: number = 5;
  public numberOfTrys: number = 0;

  constructor(
    private readonly authService: AuthService,
    private readonly usersApiService: UsersApiService,
    private readonly authenticationService: AuthentificationService,
    private readonly router: Router,
    private readonly dataStoreService: DataStoreService,
    private readonly medicalPracticesService: MedicalPracticesService,
    private readonly paymentApiService: PaymentApiService,
    private readonly paymentService: PaymentService,
  ) { }

  updateConnectedUserSubscription(subscription: UserSubscription): void {
    this.dataStoreService.setConnectedUserSubscription(subscription);
    this.userSubscribed.emit(this.dataStoreService.connectedUser$.value);
  }

  init(): void {
    // for (let i = 0; i < localStorage.length; i += 1) {
    //   const key = localStorage.key(i);
    //   if (key && key.startsWith('@@auth0spajs@@') && !key.includes('@@user@@')) {
    //     const value: any = JSON.parse(localStorage.getItem(key));
    //     // localStorage.setItem(key, JSON.stringify(value));
    //   }
    // }
    let existingUser = false;

    const getToken = this.authService.getAccessTokenSilently({
      cacheMode: 'off',
    });
    const getUser = this.authService.user$;

    combineLatest([getToken, getUser])
      .pipe(
        take(1),
        switchMap((results: any[]) => {
          const token: string = results[0];
          this.dataStoreService.setAuthUser(results[1]);
          if (!this.dataStoreService.oAuthUser$.value || !token) {
            return [];
          }

          this.oAuthUserInitialized.emit(this.dataStoreService.oAuthUser$.value);

          this.authenticationService.updateToken(
            new TokenModel(
              token,
              this.dataStoreService.oAuthUser$.value.sub,
              this.dataStoreService.oAuthUser$.value.picture,
              'fr',
              null,
              this.dataStoreService.oAuthUser$.value.nickname,
              this.dataStoreService.oAuthUser$.value.nickname.split('.')[0],
              this.dataStoreService.oAuthUser$.value.nickname.split('.')[1],
              this.dataStoreService.oAuthUser$.value.email,
            ),
          );

          if (this.dataStoreService.oAuthUser$.value?.email_verified === false) {
            return [];
          }
          return this.usersApiService.getConnectedUser();
        }),
        switchMap((response: ApiResponse<any>) => {
          if (response.errorCode === 404 || response.errorCode === 403) {
            // user not exist
            setTimeout(() => {
              this.usersApiService.createConnectedUser().subscribe((response: ApiResponse<string>) => {
                this.dataStoreService.setConnectedUserSubscription(null);
                if (response.success) {
                  window.location.href = '/profile';
                }
              });
            }, 1000);
            return [];
          }

          if (response.success) {
            existingUser = true;
            this.dataStoreService.setConnectedUser(new User(response.data));
            return this.paymentApiService.getUserSubscription();
          }
          return [];
        }),
        switchMap((userSubscription: UserSubscription) => {
          this.updateConnectedUserSubscription(userSubscription);
          this.paymentApiService.getLastInvoiceStatus().subscribe((data: InvoiceStatus) => {
            if (data && !data.paymentValid) {
              this.paymentService.triggerPaymentValidation(data.paymentLink);
            }
          });
          // poll payment status until payment ok => remove softlock
          return [];
        }),
      ).pipe(
        catchError((error: any) => {
          console.log('error', error);
          if (this.numberOfTrys < this.maxTry) {
            this.numberOfTrys += 1;
            this.init();
          } else {
            this.dataStoreService.setConnectedUserSubscription(null);
          }
          return [];
        }),
        finalize(() => {
          if (existingUser) {
            this.userInitialized.emit(this.dataStoreService.connectedUser$.value);
            this.medicalPracticesService.getById(this.dataStoreService.connectedUser$.value.currentMedicalPractice).subscribe();
          }
        }),
      )
      .subscribe();
  }
}
