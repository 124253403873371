import { Injectable } from '@angular/core';
import { DataStoreService } from '../data-store/data-store.service';
import { ApiPollingService } from '../api-polling/api-polling.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private readonly dataStoreService: DataStoreService,
    private readonly apiPollingService: ApiPollingService,
  ) { }

  triggerPaymentValidation(paymentLink: string): void {
    this.dataStoreService.setPaymentAwaitingValidation(paymentLink);
    window.open(
      paymentLink,
      'myWindow',
      'width=500,height=800',
    );
    this.apiPollingService.pollLastInvoiceStatus();
  }
}
