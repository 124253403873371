import { SubscriptionOfferGroup } from './subscription-offer-group.model';

export class UserSubscription {
  subscriptionOfferGroup: SubscriptionOfferGroup;
  start: Date;
  end: Date;
  active: boolean;
  id: string;
  renewable: boolean;
  subscriptionDate: Date;

  constructor(data: Partial<UserSubscription>) {
    Object.assign(this, data);
    this.start = new Date(data?.start);
    this.end = new Date(data?.end);
    this.subscriptionDate = new Date(data?.subscriptionDate);

    if (data?.active === null) {
      const today = new Date();
      this.active = today > this.start && today < this.end;
    }
  }
}
